import telegram from "../../assets/telegram.png";
import dextools from "../../assets/Dextools.png";
import uniswap from "../../assets/uniswap.png";

const Footer = () => {
  return (
    <div className="w-full py-5 flex justify-center items-center">
      <div className="flex flex-col items-center w-full max-w-screen-xl">
        {/* Logo */}
        <div></div>

        {/* socials */}
        <div className="flex gap-[30px]">
          <a
            href="https://t.me/BasedMelvin"
            target="_blank"
            rel="noreferrer"
          >
            <div className="flex items-center gap-2">
              <img
                src={telegram}
                alt="tg"
                className="w-[15px] sm:w-[20px]"
              />
              <span className="text-[16px] sm:text-[24px] text-white">
                Telegram
              </span>
            </div>
          </a>

          <a
            href="https://www.dextools.io/app/en/base/pair-explorer/0xfdfa9634cd1aec3c9a5548c024f4af00ad21573e?t=1710812188688"
            target="_blank"
            rel="noreferrer"
          >
            <div className="flex items-center gap-2">
              <img
                src={dextools}
                alt="tg"
                className="w-[15px] sm:w-[20px]"
              />
              <span className="text-[16px] sm:text-[24px] text-white">
                Dextools
              </span>
            </div>
          </a>

          <a
            href="https://app.uniswap.org/explore/tokens/base/0x0a2bd90ef16d4b079e5cf4eaf814182c91add4b8"
            target="_blank"
            rel="noreferrer"
          >
            <div className="flex items-center gap-1">
              <img
                src={uniswap}
                alt="tg"
                className="w-[30px] sm:w-[40px]"
              />
              <span className="text-[16px] sm:text-[24px] text-white">
                Uniswap
              </span>
            </div>
          </a>
        </div>

        {/* rights */}
        <div className="text-white text-[16px]">
          © 2024 Melvin Team. Meow rights reserved.
        </div>
      </div>
    </div>
  );
};

export default Footer;
