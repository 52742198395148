import {
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers/react";
import {
  BrowserProvider,
  Contract,
  JsonRpcProvider,
  formatEther,
  formatUnits,
  parseEther,
  parseUnits,
} from "ethers";
import { useContext, useEffect, useState } from "react";
import melvinAbi from "../contracts/melvinAbi.json";
import abi from "../contracts/abi.json";

import { AppContext } from "../context/app.context";

const NewLock = () => {
  const { errorToast, successToast, extractRevertReason } =
    useContext(AppContext);
  const { walletProvider } = useWeb3ModalProvider();

  const { isConnected, address } = useWeb3ModalAccount();
  const [userBal, setUserBal] = useState(0);
  const [curMarketCap, setCurMarketCap] = useState();
  const [targetMc, setTargetMc] = useState(0);
  const [amount, setAmount] = useState("");
  const [loadingData, setLoadingData] = useState(false);

  const baseProvider = new JsonRpcProvider("https://base.llamarpc.com");
  const lockCa = "0xEFE7905bFCA8586C4582D81EC38dffe67791109C";
  const melvin = "0x0a2BD90eF16d4B079E5cf4eaF814182C91adD4b8";

  function removeDecimal(number) {
    // Convert the number to a string
    let numberStr = number.toString();

    // Find the position of the decimal point
    let decimalPosition = numberStr.indexOf(".");

    // If there is no decimal point, return the original number
    if (decimalPosition === -1) {
      return number;
    }

    // Otherwise, return the part of the string before the decimal point
    return parseFloat(numberStr.substring(0, decimalPosition));
  }

  const fetchGeneralData = async () => {
    if (!isConnected) return;
    setLoadingData(true);

    try {
      const contract = new Contract(melvin, melvinAbi, baseProvider);
      const userBal = await contract.balanceOf(address);

      const lockContract = new Contract(lockCa, abi, baseProvider);
      const curMc = await lockContract.getMarketCap();

      setCurMarketCap(removeDecimal(formatUnits(curMc, 6)));
      setUserBal(removeDecimal(formatUnits(userBal, 18)));
      setLoadingData(false);
    } catch (error) {
      setLoadingData(false);
      console.log(error);
    }
  };

  const handleMcChange = (e) => {
    setTargetMc(e.target.value);
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const submitData = async () => {
    if (amount <= 0 || "") return errorToast("Invalid Amount");

    const provider = new BrowserProvider(walletProvider);
    const signer = await provider.getSigner();

    try {
      const melvinContr = new Contract(melvin, melvinAbi, signer);
      const lockMelvin = new Contract(lockCa, abi, signer);

      const amountInWei = parseEther(amount.toString());
      const approveTokens = await melvinContr.approve(lockCa, amountInWei);
      await approveTokens.wait();

      // Format targetMc to 6 decimals
      const targetMcFormatted = parseUnits(targetMc.toString(), 6);

      const lockMel = await lockMelvin.createNewLock(
        amountInWei,
        targetMcFormatted
      );

      await lockMel.wait();
      successToast("Tokens Successfully Locked");
    } catch (error) {
      console.log(error);
      const revertReason = extractRevertReason(error);
      errorToast(revertReason);
    }
  };

  useEffect(() => {
    fetchGeneralData();
  }, [isConnected, address]);

  return (
    <div className="w-full">
      <div className="flex flex-col">
        <div className="mt-10 flex items-center justify-center w-full">
          {isConnected ? (
            <div className="text-white px-8  w-[600px] border-[4px] border-[#AB56F1] rounded-[12px] bg-[#54B8E9]">
              <h1 className="text-2xl font-bold text-center mb-4">
                Create Melvin Lock
              </h1>

              <div className="mb-4">
                <label className="flex justify-between text-sm font-medium mb-2">
                  Amount
                  <span>
                    your balance: {loadingData ? "searching..." : userBal}
                  </span>
                </label>

                <div className="flex relative">
                  <input
                    value={amount}
                    onChange={handleAmountChange}
                    type="number"
                    className="shadow-sm text-black rounded-md w-full px-3 py-2 border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    placeholder="69.420 Melvin"
                  />
                  <button
                    onClick={() => {
                      setAmount(removeDecimal(userBal));
                    }}
                    className="absolute rounded right-2 top-1 p-1 bg-[#F1963C]"
                  >
                    max
                  </button>
                </div>
              </div>
              <div className="mb-4">
                <div className="flex justify-between text-sm font-medium mb-2">
                  Target Marketcap
                  <span>
                    Current MC: {loadingData ? "searching..." : curMarketCap}
                  </span>
                </div>
                <input
                  onChange={handleMcChange}
                  type="number"
                  className="shadow-sm text-black rounded-md w-full px-3 py-2 border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  placeholder="100.000"
                />
              </div>
              <div className="flex items-center justify-center mb-4">
                <button
                  onClick={submitData}
                  className="font-semibold text-white text-[14px] p-4 bg-[#F1963C] rounded"
                >
                  Lock Melvin
                </button>
              </div>
            </div>
          ) : (
            <h2 className="text-white my-10 text-[30px] sm:text-[40px]">
              Please, connect wallet.
            </h2>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewLock;
