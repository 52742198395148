import { useEffect, useState } from "react";
import ListCard from "../components/UI/ListCard";
import axios from "axios";
import loadingMelvin from "../assets/loading.gif";

const Home = () => {
  const [locksList, setLocksList] = useState();
  const [loading, setLoading] = useState(false);

  const getLocks = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        "https://melvinserver-production.up.railway.app/api/getalllocks"
      );

      setLocksList(response.data.reverse().slice(0, 20));
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getLocks();
  }, []);

  return (
    <div className="w-full">
      <h2 className="text-[30px] text-center sm:text-[40px] text-white mt-6 mb-3">
        Recent Melvin Locks:
      </h2>

      <div className="flex flex-col justify-center items-center w-full">
        {loading && (
          <div className="w-full py-10 flex items-center justify-center">
            <img
              src={loadingMelvin}
              alt=""
              className="w-[200px]"
            />
          </div>
        )}

        <div className="flex max-w-[700px] w-full flex-col gap-1">
          {locksList?.map((item) => (
            <ListCard
              key={item._id}
              amtLocked={item.amount}
              user={item.user}
              targetMc={item.targetMC}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Home;
