import { useState, useEffect } from "react";
import LockCard from "../components/UI/LockCard";
import abi from "../contracts/abi.json";
import { Contract, JsonRpcProvider, formatUnits } from "ethers";
import { useWeb3ModalAccount } from "@web3modal/ethers/react";
import loadingMelvin from "../assets/loading.gif";

const Positions = () => {
  const lockCa = "0xEFE7905bFCA8586C4582D81EC38dffe67791109C";
  const { isConnected, address } = useWeb3ModalAccount();
  const [userLocks, setUserLocks] = useState([]);
  const [loading, setLoading] = useState(false);

  const getLockedTokens = async (refresh) => {
    if (!refresh) setLoading(true);

    try {
      const baseProvider = new JsonRpcProvider(
        "https://rpc.ankr.com/base/2366113645922cce06378ef04ab1901c6e1e111afd9dfcb5bb16820aa672164a"
      );

      const lockContract = new Contract(lockCa, abi, baseProvider);
      const userLocksData = await lockContract.getAllLocks(address);

      setUserLocks(userLocksData);
      setLoading(false);
      console.log(userLocksData);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isConnected) return;
    getLockedTokens();

    const intervalId = setInterval(() => getLockedTokens(true), 60000);
    return () => clearInterval(intervalId);
  }, [isConnected, address]);

  function removeDecimal(number) {
    // Convert the number to a string
    let numberStr = number.toString();

    // Find the position of the decimal point
    let decimalPosition = numberStr.indexOf(".");

    // If there is no decimal point, return the original number
    if (decimalPosition === -1) {
      return number;
    }

    // Otherwise, return the part of the string before the decimal point
    return parseFloat(numberStr.substring(0, decimalPosition));
  }

  return (
    <div className="w-full">
      <div className="flex mt-10 gap-2 justify-center py-5 flex-wrap">
        {loading && (
          <div className="w-full py-10 flex items-center justify-center">
            <img
              src={loadingMelvin}
              alt=""
              className="w-[200px]"
            />
          </div>
        )}

        {!isConnected && (
          <h2 className="text-white my-10 text-[30px] sm:text-[40px]">
            Please, connect wallet.
          </h2>
        )}

        {isConnected && !loading && userLocks?.length === 0 && (
          <div className="text-[30px] text-white mt-20">You have no locks!</div>
        )}

        {userLocks?.map((lock) => (
          <LockCard
            key={lock[1]}
            lockId={lock[1]}
            nonce={lock[1]}
            amountLocked={lock[2].toString()}
            withdrawn={formatUnits(lock[2]) - formatUnits(lock[3])}
            target={removeDecimal(formatUnits(lock[4], 6))}
            refresher={getLockedTokens}
            removeDecimal={removeDecimal}
            finalized={lock[7]}
            remaining={lock[3]}
            mcReached={lock[6]}
            lastClaim={lock[5]}
          />
        ))}
      </div>
    </div>
  );
};

export default Positions;
