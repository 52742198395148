import { JsonRpcProvider } from "ethers";
import { createContext } from "react";
import { toast } from "react-toastify";

export const AppContext = createContext();

const ContextProvider = ({ children }) => {
  const successToast = (msg) => {
    toast.success(msg, {
      position: "bottom-left",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  const errorToast = (msg) => {
    toast.error(msg, {
      position: "bottom-left",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  const extractRevertReason = (error) => {
    const matches = error.message.match(/execution reverted: "(.*?)"/);
    if (matches && matches.length > 1) {
      return matches[1];
    }
    return null;
  };

  const baseProvider = new JsonRpcProvider(
    "https://rpc.ankr.com/base/2366113645922cce06378ef04ab1901c6e1e111afd9dfcb5bb16820aa672164a"
  );

  return (
    <AppContext.Provider
      value={{
        successToast,
        errorToast,
        extractRevertReason,
        baseProvider,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default ContextProvider;
