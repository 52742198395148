import {
  BrowserProvider,
  Contract,
  JsonRpcProvider,
  formatUnits,
} from "ethers";
import melvin from "../../../assets/MelLaser.png";
import {
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers/react";
import { useContext, useState } from "react";
import abi from "../../../contracts/abi.json";
import { AppContext } from "../../../context/app.context";
import lock from "../../../assets/padlock.png";

const LockCard = ({
  amountLocked,
  withdrawn,
  target,
  nonce,
  refresher,
  removeDecimal,
  finalized,
  remaining,
  mcReached,
  lastClaim,
}) => {
  const { isConnected, address } = useWeb3ModalAccount();
  const [loading, setLoading] = useState(false);
  const [emergencyAlert, setEmergencyAlert] = useState(false);
  const { walletProvider } = useWeb3ModalProvider();
  const lockCa = "0xEFE7905bFCA8586C4582D81EC38dffe67791109C";

  const { errorToast, successToast, extractRevertReason } =
    useContext(AppContext);

  const emergengyWithdraw = async () => {
    setLoading(true);
    setEmergencyAlert(false);
    try {
      const provider = new BrowserProvider(walletProvider);
      const signer = await provider.getSigner();

      const lockContract = new Contract(lockCa, abi, signer);
      const userLocksData = await lockContract.emergencyWithdraw(nonce);

      await userLocksData.wait();
      refresher();
      setLoading(false);
      successToast("Emergency Withdraw Executed!");
    } catch (error) {
      setLoading(false);
      console.log(error);
      errorToast(extractRevertReason(error));
    }
  };

  const dailyClaim = async () => {
    setLoading(true);
    try {
      const provider = new BrowserProvider(walletProvider);
      const signer = await provider.getSigner();

      const lockContract = new Contract(lockCa, abi, signer);
      const userClaim = await lockContract.withdrawLock(nonce);

      await userClaim.wait();
      refresher();

      setLoading(false);
      successToast("Token Claim Executed!");
    } catch (error) {
      console.log(error);
      setLoading(false);
      errorToast(extractRevertReason(error));
    }
  };

  const countdownFromLastClaim = (lastClaimTimestampInSeconds) => {
    const currentTimestampInSeconds = Math.floor(Date.now() / 1000); // Current timestamp in seconds

    // Calculate time since last claim in seconds
    const timeSinceLastClaim =
      currentTimestampInSeconds - lastClaimTimestampInSeconds;

    // Calculate remaining time until 24 hours have elapsed
    const remainingTime = 24 * 60 * 60 - timeSinceLastClaim;

    // Calculate hours and minutes
    const hours = Math.floor(remainingTime / 3600);
    const minutes = Math.floor((remainingTime % 3600) / 60);

    // Construct the countdown string
    let countdownString = "";
    if (hours > 0) {
      countdownString += `${hours}h `;
    }
    countdownString += `${minutes}min`;
    console.log("Countdown string", countdownString);
    const response = countdownString.includes("-") ? -1 : countdownString;
    return response;
  };

  return (
    <div className="max-w-sm relative">
      <div className="bg-white shadow-lg rounded-lg p-6">
        <div className="flex items-center mb-4">
          {/* locked */}
          {finalized && (
            <div className="w-full flex-col gap-2 flex items-center justify-center absolute bg-[#0000007c] rounded-lg top-0 bottom-0 left-0 right-0">
              <img
                src={lock}
                alt="padlock"
                className="w-[40px]"
              />
              <span className="text-white"> This lock is finalized!</span>
            </div>
          )}

          {emergencyAlert && (
            <div className="flex fixed w-full items-center justify-center right-0 top-0 bottom-0 bg-[#cccccc5b] z-10">
              <div className="flex flex-col max-w-[400px] text-center gap-3 p-3 rounded-[12px] bg-[#fff] border items-center justify-center">
                <h2 className="text-[40px]">Meow!</h2>
                <span>
                  This is a destructive action, half of your unclaimed tokens
                  will be burnt!
                </span>

                <div className="flex gap-2 mt-2">
                  <button
                    onClick={() => setEmergencyAlert(false)}
                    className="flex p-2 rounded bg-[#ccc]"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={emergengyWithdraw}
                    className="flex p-2 rounded text-white bg-[#e83232]"
                  >
                    Proceed
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* text infos */}
          <div>
            <div className="text-gray-600 text-sm">
              Amount Locked: {removeDecimal(formatUnits(amountLocked, 18))}
            </div>
            <div className="text-gray-900 text-2xl font-semibold">
              <span className="text-orange-500 gap-2 text-sm font-medium flex items-center">
                Target MC <span>{target}</span>
              </span>
              <span className="text-gray-400 text-[12px] -mt-1 gap-2 text-sm font-medium flex items-center">
                Amount withdrawn <span>{withdrawn}</span>
              </span>
              <span className="text-gray-400 text-[12px] -mt-1 gap-2 text-sm font-medium flex items-center">
                Remaining to Claim <span> {formatUnits(remaining, 18)}</span>
              </span>
              <span className="text-gray-400 text-[12px] -mt-1 gap-2 text-sm font-medium flex items-center">
                <div>
                  {mcReached ? (
                    <span className="text-green-400">MC Reached</span>
                  ) : (
                    <span className="text-red-400">MC Not Reached</span>
                  )}
                </div>
              </span>
            </div>
          </div>
        </div>

        {/* Action Buttons */}
        <div className="flex gap-2 mb-4">
          {mcReached &&
          countdownFromLastClaim(formatUnits(lastClaim, 0)) === -1 ? (
            <button
              onClick={dailyClaim}
              className="w-full text-purple-600 bg-purple-100 hover:bg-purple-200 text-sm py-2 px-4 rounded-md transition duration-300 ease-in-out"
            >
              Claim 3% Daily
            </button>
          ) : (
            <button className="w-full text-purple-600 bg-purple-300 hover:bg-purple-200 text-sm py-2 px-4 rounded-md transition duration-300 ease-in-out">
              Unavailable!
            </button>
          )}

          <button
            onClick={() => setEmergencyAlert(true)}
            className="w-full text-purple-600 bg-red-100 hover:bg-purple-200 text-sm py-2 px-4 rounded-md transition duration-300 ease-in-out"
          >
            Emergency Withdraw
          </button>
        </div>

        {/* Timer message */}
        <div className="">
          {!mcReached ? null : finalized ? (
            <span className="text-black w-full flex justify-center">
              This lock is finalized.
            </span>
          ) : (
            <>
              {(countdownFromLastClaim(formatUnits(lastClaim, 0)) === -1) ===
              -1 ? (
                <span>Claim Available!</span>
              ) : (
                <>
                  <span>Time until next claim:</span>
                  {countdownFromLastClaim(formatUnits(lastClaim, 0)) === -1
                    ? " 0min"
                    : countdownFromLastClaim(formatUnits(lastClaim, 0))}
                </>
              )}
            </>
          )}

          {!finalized && !mcReached && (
            <span className="text-black w-full flex justify-center">
              Target not reached.
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default LockCard;
