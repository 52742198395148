import { useWeb3Modal, useWeb3ModalAccount } from "@web3modal/ethers/react";
import { shortAddress } from "../../utils/index";
import logo from "../../assets/logo.jpg";
import { Link } from "react-router-dom";

const Header = () => {
  const { open } = useWeb3Modal();
  const { isConnected, address } = useWeb3ModalAccount();

  const connectWallet = () => {
    try {
      open();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="w-full flex justify-center items-center">
      <div className="flex w-full items-center justify-between p-2 max-w-screen-xl">
        <h1 className="w-[150px] rounded-xl">
          <img
            src={logo}
            alt=""
            className="rounded-lg shadow-sm"
          />{" "}
        </h1>

        <div className="flex">
          <Link>Home</Link>

          <button
            onClick={connectWallet}
            className="bg-[#EC4B7D] hover:bg-[#cd3f6c] text-white font-bold py-2 px-4 rounded"
          >
            {isConnected ? shortAddress(address) : "Connect Wallet"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
