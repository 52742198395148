import { useWeb3Modal } from "@web3modal/ethers/react";
import { NavLink, Outlet } from "react-router-dom";
import melvinScreamin from "./assets/melvinScreamin.png";
import { useContext, useEffect, useState } from "react";
import { Contract, formatUnits } from "ethers";
import melvinAbi from "./contracts/melvinAbi.json";
import { AppContext } from "./context/app.context";

const Main = () => {
  const [melvinTvl, setMelvinTvl] = useState("loading...");
  const { baseProvider } = useContext(AppContext);
  const lockCa = "0xEFE7905bFCA8586C4582D81EC38dffe67791109C";
  const melvin = "0x0a2BD90eF16d4B079E5cf4eaF814182C91adD4b8";

  function removeDecimal(number) {
    // Convert the number to a string
    let numberStr = number.toString();

    // Find the position of the decimal point
    let decimalPosition = numberStr.indexOf(".");

    // If there is no decimal point, return the original number
    if (decimalPosition === -1) {
      return number;
    }

    // Otherwise, return the part of the string before the decimal point
    return parseFloat(numberStr.substring(0, decimalPosition));
  }

  const fetchTokensLocked = async () => {
    console.log("first");
    try {
      const melvinCa = new Contract(melvin, melvinAbi, baseProvider);
      const tvl = await melvinCa.balanceOf(lockCa);

      setMelvinTvl(formatUnits(tvl, 18));
      console.log("saved");
    } catch (error) {
      console.log(error);
      console.log("er");
    }
  };

  useEffect(() => {
    fetchTokensLocked();
  }, []);
  return (
    <div className="flex py-5 justify-center">
      <div className="max-w-screen-lg px-1 flex-col sm:py-[100px] py-[60px] w-full">
        {/* banner */}
        <div className="w-full pt-4 flex items-center md:justify-center justify-around sm:gap-[50px] border-[5px] px-5 rounded-[10px] border-[#EF953F] bg-[#AB56F1]">
          {/* stats */}
          <div className="h-full">
            <div className="sm:text-[35px] text-[20px] flex flex-col text-white">
              {/* Total tokens */}
              <div className="flex flex-col sm:flex-row">
                <div className="w-[200px] md:w-auto">Total Melvin Locked:</div>
                <div>{removeDecimal(melvinTvl)}</div>
              </div>

              {/* percentage */}
              <span className="sm:text-[30px] text-[15px]">
                {(
                  Number(removeDecimal(melvinTvl) / 420000000000).toFixed(4) *
                  100
                ).toFixed(2)}
                % Total Supply
              </span>
            </div>
          </div>

          {/* melving image */}
          <img
            src={melvinScreamin}
            alt="melvin"
            className="w-[120px] sm:w-[200px]"
          />
        </div>

        {/* Nav */}
        <div className="flex mt-10 justify-between gap-2">
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive
                ? "flex border-[3px] rounded-[10px] w-[100px] justify-center border-[#F1963C] h-[40px] items-center bg-[#EC4B7F] p-3 text-white"
                : "flex border-[3px] rounded-[10px] w-[100px] justify-center border-[#F1963C] h-[40px] items-center bg-[#F1963C] p-3 text-white"
            }
          >
            <span>Home</span>
          </NavLink>

          <div className="flex gap-2">
            <NavLink
              to="/new-lock"
              className={({ isActive }) =>
                isActive
                  ? "flex border-[3px] rounded-[10px] w-[100px] justify-center border-[#F1963C] h-[40px] items-center bg-[#EC4B7F] p-3 text-white"
                  : "flex border-[3px] rounded-[10px] w-[100px] justify-center border-[#F1963C] h-[40px] items-center bg-[#F1963C] p-3 text-white"
              }
            >
              <span>New lock</span>
            </NavLink>

            <NavLink
              to="/positions"
              className={({ isActive }) =>
                isActive
                  ? "flex border-[3px] rounded-[10px] w-[100px] justify-center border-[#F1963C] h-[40px] items-center bg-[#EC4B7F] p-3 text-white"
                  : "flex border-[3px] rounded-[10px] w-[100px] justify-center border-[#F1963C] h-[40px] items-center bg-[#F1963C] p-3 text-white"
              }
            >
              <span>Positions</span>
            </NavLink>
          </div>
        </div>

        <div className="">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Main;
