import { shortAddress, longAddress } from "../../../utils";
import share from "../../../assets/share.png";
import copy from "../../../assets/copy.png";
import correct from "../../../assets/correct.png";

import { CopyToClipboard } from "react-copy-to-clipboard";
import { useState } from "react";

const ListCard = ({ amtLocked, user, targetMc }) => {
  const [isCopied, setIsCopied] = useState(false);

  const setCopied = () => {
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  return (
    <div className="w-full rounded-[10px] flex-col items-center sm:items-start gap-2 flex sm:flex-row justify-around border-[#AB56F1] border-[4px] text-white bg-[#67D8A1] p-4 font-medium">
      {/* Lock amount */}
      <div className="flex flex-col items-center sm:items-start sm:flex-row max-w-[400px] w-full gap-2 sm:gap-4">
        <div className="flex gap-2">
          {/* short add */}
          <span className="hidden sm:flex">{shortAddress(user)}</span>

          {/* long add */}
          <span className="sm:hidden">{longAddress(user)}</span>

          <CopyToClipboard
            className="cursor-pointer"
            text={user}
            onCopy={setCopied}
          >
            <button>
              <img
                src={isCopied ? correct : copy}
                alt="copy"
                className="w-5"
              />
            </button>
          </CopyToClipboard>
        </div>
        <div className=""> locked {amtLocked} Melvin</div>
      </div>

      {/* Target */}
      <div className="max-w-[200px] text-center w-full">
        Target MC: {targetMc}
      </div>
    </div>
  );
};

export default ListCard;
